import * as React from "react"
import Layout from "../../components/layout"
import '../../style.css'
import Star from "../../components/star";
import DownloadButton from "../../components/downloadButton";
import InternalButton from "../../components/internalButton";

const TOPS = () => {
    const folder = '/downloads/tops'

    return (
        <Layout>
            <div>
                <h1>TOPS Lesson Modules</h1>
                <Star />
                <p>
                    <a href="https://topscience.org/" target='_blank' rel="noreferrer">TOPS Learning Systems</a> has developed a three-booklet series of activities based on the math and science of the Fermi mission. In the TOPS tradition, each booklet has activities which use simple, inexpensive household materials (such as coins, paper plates, thread spools, etc.) in clever ways.
                    Booklet 1, "Far Out Math!": Students measure, scale, graph and problem solve, using examples derived from Fermi. They will compare quantities as orders of magnitude, become familiar with scientific notation, and develop a concrete understanding of exponents and logarithms.
                </p>
                <div className='BHRA-button-container'>

                        <div className="item-container" style={{margin:'5% 0'}}>
                            <img src='/fom.png' className='image-styles' alt='media'/> {/*change images*/}
                            <h4 style={{margin:'20px'}}>Far Out Math!</h4>
                            <DownloadButton  link={`${folder}/topsmod1.pdf`}/>
                        </div> 

                        <div className="item-container" style={{margin:'5% 0'}}>
                            <img src='/tops.png' className='image-styles' alt='media'/> {/*change images*/}
                            <h4 style={{margin:'20px'}}>TOPS Far Out Math PPT</h4>
                            <InternalButton  link='/ppt/farOutMath' />
                        </div> 
                    
                    {/* <DownloadButton text='Far Out Math!' link={`${folder}/topsmod1.pdf`} buttonStyles = {ButtonStylesClass}/> */}
                    {/* <InternalButton text='TOPS Far Out Math PPT' link='/ppt/farOutMath' buttonStyles = {ButtonStylesClass}/> */}
                </div>

                <p>Booklet 2, "Scale the Universe": The size and scale of the Universe are among the most difficult concepts to understand. This booklet gently takes students through the orders-of-magnitude of the Universe starting with the familiar human scale, then going down to the infinitesimal and out to the astronomical.</p>
                
                <div className="item-container" style={{margin:'5% 0'}}>
                    <img src='/scale.png' className='image-styles' alt='media'/> {/*change images*/}
                    <h4 style={{margin:'20px'}}>Scale The Universe</h4>
                    <DownloadButton  link={`${folder}/scale_universe.pdf`} />
                </div> 
                {/* <DownloadButton text='Scale The Universe' link={`${folder}/scale_universe.pdf`} buttonStyles = {ButtonStylesClass}/> */}

                <p>Booklet 3, "Pi in the Sky": These activities teach about radians and degree, angles, parallax, and apparent sizes of objects. Students will measure the apparent sizes of objects at various distances and learn about how we can measure the sizes of distant objects.</p>
                
                <div className='BHRA-button-container'>
                        <div className="item-container" style={{margin:'5% 0'}}>
                            <img src='/pi.png' className='image-styles' alt='media'/> {/*change images*/}
                            <h4 style={{margin:'20px'}}>Pi in the Sky</h4>
                            <DownloadButton  link={`${folder}/pi_in_the_sky.pdf`}/>
                        </div> 

                        <div className="item-container" style={{margin:'5% 0'}}>
                            <img src='/scaleppt.png' className='image-styles' alt='media'/> {/*change images*/}
                            <h4 style={{margin:'20px'}}>Scale The Universe PPT</h4>
                            <InternalButton  link='/ppt/piSky'/>
                        </div> 
                    {/* <DownloadButton text='Pi in the Sky' link={`${folder}/pi_in_the_sky.pdf`} buttonStyles = {ButtonStylesClass}/>
                    <InternalButton text='Scale The Universe PPT' link='/ppt/piSky' buttonStyles = {ButtonStylesClass}/> */}
                </div>

            </div>
        </Layout>
    )
}

export default TOPS